import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';

const About2 = ({ data }) => (
  <Layout>
    <Head pageTitle={data.about2Json.title} />
    <Box>
      <h2 className="page-title">{data.about2Json.title}</h2>
      <div
        className="page-content-markdown"
        dangerouslySetInnerHTML={{
          __html: data.about2Json.content.childMarkdownRemark.html,
        }}
      />
    </Box>
  </Layout>
);

About2.propTypes = {
  data: PropTypes.object.isRequired,
};

export default About2;

export const query = graphql`
  query About2Query {
    about2Json {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
